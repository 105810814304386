#user-login label, #user-register-form label, form#user-pass label {
  text-align: left;
  font-size: 20px;
  font-weight: 100;
  padding-right: 1em;
  color: #1D2731; }

#user-login div.description, #user-register-form div.description, form#user-pass div.description {
  font-size: 10px;
  font-weight: normal;
  color: #1D2731; }

#user-login input#edit-name,
#user-login input#edit-pass {
  font-weight: 100;
  background-color: #f0f1f4;
  border: 1px solid #bbb;
  padding: 5px; }

#user-login label {
  width: 130px; }

#user-login input {
  max-width: 300px; }
  #user-login input#edit-submit {
    max-width: 300px; }
    @media (min-width: 461px) {
      #user-login input#edit-submit {
        width: 150px; } }

#page-user, #page-register, #page-password, #page-one-time-link {
  background-color: white; }
  @media (max-width: 530px) {
    #page-user ul.tabs.primary, #page-register ul.tabs.primary, #page-password ul.tabs.primary, #page-one-time-link ul.tabs.primary {
      font-size: 12px; } }
  #page-user input[type="submit"], #page-register input[type="submit"], #page-password input[type="submit"], #page-one-time-link input[type="submit"] {
    font-size: 20px;
    font-weight: 200; }
  #page-user #main, #page-register #main, #page-password #main, #page-one-time-link #main {
    width: 100%;
    padding: 10px;
    text-align: center;
    color: #777;
    font: normal 100% sans-serif;
    font-family: "Lato", sans-serif;
    /* I think this is what our website uses... */ }
  #page-user body, #page-register body, #page-password body, #page-one-time-link body {
    background-color: white;
    color: #777;
    font: normal 100% sans-serif;
    font-family: "Lato", sans-serif;
    /* I think this is what our website uses... */ }
  #page-user #small-title, #page-register #small-title, #page-password #small-title, #page-one-time-link #small-title {
    color: #5593d1;
    font-weight: 200; }
  #page-user a, #page-register a, #page-password a, #page-one-time-link a {
    text-decoration: none; }
    #page-user a:not(.current), #page-register a:not(.current), #page-password a:not(.current), #page-one-time-link a:not(.current) {
      font-weight: 100; }
  #page-user a:hover, #page-user a:active, #page-register a:hover, #page-register a:active, #page-password a:hover, #page-password a:active, #page-one-time-link a:hover, #page-one-time-link a:active {
    text-decoration: underline; }
  #page-user img.logo, #page-register img.logo, #page-password img.logo, #page-one-time-link img.logo {
    max-height: 200px;
    max-width: 100%; }

input#edit-mail {
  max-width: 100%;
  background-color: #f0f1f4;
  border: 1px solid #bbb;
  padding: 5px;
  font-weight: 100; }

#page-register div#main-wrapper {
  text-align: center; }
  #page-register div#main-wrapper div.content {
    padding-top: 0px; }

#page-register div#main {
  text-align: left;
  width: 100%;
  max-width: 500px; }
  #page-register div#main #edit-autoassignrole-user {
    max-width: 300px; }
  #page-register div#main div.form-item-user-roles div.description {
    float: left;
    clear: left;
    position: relative; }
  #page-register div#main #edit-user-roles {
    float: right;
    position: relative;
    top: -10px; }

#page-register #user-register-form legend, #page-one-time-link #user-register-form legend {
  border: none;
  margin-bottom: 0px; }
  #page-register #user-register-form legend span.fieldset-legend, #page-one-time-link #user-register-form legend span.fieldset-legend {
    background: none; }

#page-register #user-register-form div.description, #page-one-time-link #user-register-form div.description {
  font-size: 10px;
  font-weight: normal;
  color: #1D2731; }

#page-register #user-register-form fieldset#edit-timezone, #page-one-time-link #user-register-form fieldset#edit-timezone {
  display: none; }
  #page-register #user-register-form fieldset#edit-timezone a.fieldset-title, #page-one-time-link #user-register-form fieldset#edit-timezone a.fieldset-title {
    font-size: 30px;
    font-style: normal;
    font-weight: normal;
    color: #2075b0; }
  #page-register #user-register-form fieldset#edit-timezone select#edit-timezone--2, #page-one-time-link #user-register-form fieldset#edit-timezone select#edit-timezone--2 {
    max-width: 100%; }

#page-register #user-register-form div.form-item-mollom-captcha, #page-one-time-link #user-register-form div.form-item-mollom-captcha {
  padding: 25px 10px 35px 10px;
  margin: auto;
  max-width: 550px;
  width: 100%;
  overflow: auto;
  border: 1px #5593d1 solid;
  border-radius: 15px; }
  #page-register #user-register-form div.form-item-mollom-captcha label[for="edit-mollom-captcha"], #page-one-time-link #user-register-form div.form-item-mollom-captcha label[for="edit-mollom-captcha"] {
    max-width: 550px;
    width: 100%;
    font-size: 0px; }
    #page-register #user-register-form div.form-item-mollom-captcha label[for="edit-mollom-captcha"]:before, #page-one-time-link #user-register-form div.form-item-mollom-captcha label[for="edit-mollom-captcha"]:before {
      font-size: 20px;
      content: "This is a spam firewall to prevent malicious software from accessing the site."; }
  #page-register #user-register-form div.form-item-mollom-captcha span.field-prefix span.mollom-captcha-container, #page-one-time-link #user-register-form div.form-item-mollom-captcha span.field-prefix span.mollom-captcha-container {
    text-align: left;
    float: right; }
    #page-register #user-register-form div.form-item-mollom-captcha span.field-prefix span.mollom-captcha-container span.mollom-image-captcha, #page-one-time-link #user-register-form div.form-item-mollom-captcha span.field-prefix span.mollom-captcha-container span.mollom-image-captcha {
      float: left;
      margin-right: 5px;
      display: block; }
    #page-register #user-register-form div.form-item-mollom-captcha span.field-prefix span.mollom-captcha-container a.mollom-refresh-captcha, #page-one-time-link #user-register-form div.form-item-mollom-captcha span.field-prefix span.mollom-captcha-container a.mollom-refresh-captcha {
      position: absolute;
      top: 0;
      left: 0; }
    #page-register #user-register-form div.form-item-mollom-captcha span.field-prefix span.mollom-captcha-container span.mollom-image-captcha-instructions, #page-one-time-link #user-register-form div.form-item-mollom-captcha span.field-prefix span.mollom-captcha-container span.mollom-image-captcha-instructions {
      padding: 5px 0px 5px 0px;
      float: right;
      font-size: 16px;
      font-weight: normal;
      color: #2075b0; }
  #page-register #user-register-form div.form-item-mollom-captcha input#edit-mollom-captcha, #page-one-time-link #user-register-form div.form-item-mollom-captcha input#edit-mollom-captcha {
    width: 160px;
    float: left; }

#page-password h2 {
  font-size: 25px;
  font-style: normal;
  font-weight: normal;
  color: #2075b0; }
